import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from 'firebase/auth'

import { Button } from 'components/Button'
import { Footer } from 'components/Footer'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'

import { resetErrorAction } from 'root-redux/actions/common'
import { setUserContactEmailAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectAppName,
  selectError,
  selectSource,
} from 'root-redux/selects/common'
import { selectUserContactEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useEmailInputField } from 'hooks/useEmailInputField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { setLoginMethodToLocalStorage } from 'helpers/setLoginMethodToLocalStorage'

import { ContactSupport } from 'modules/unsubscribe/components/ContactSupport'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { StyledLoginButton } from 'common-styles'
import {
  APP_BG_COLORS,
  APP_COLORS,
  AppName,
  EVENT_SOURCE,
  LoginMethod,
  PlatformOS,
} from 'root-constants'

import {
  LOGIN_WITH_EMAIL_FIREBASE,
  RESET_PASSWORD,
  loginWithEmailFirebaseAction,
  resetFirebaseErrorMessageAction,
  resetFirebaseInfoMessageAction,
  selectFirebaseErrorMessage,
  selectFirebaseInfoMessage,
} from '../../redux'
import { StyledEmailLogin as S } from './EmailLogin.styles'

export const EmailLogin: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName)
  const fetchingActionsList = useSelector(selectActionList)
  const errorMessage = useSelector(selectError)
  const firebaseInfoMessage = useSelector(selectFirebaseInfoMessage)
  const firebaseErrorMessage = useSelector(selectFirebaseErrorMessage)
  const currentEmail = useSelector(selectUserContactEmail)
  const source = useSelector(selectSource)

  const { t } = useTranslation()

  const [email, setEmail] = useEmailInputField()

  const [password, setPassword] = usePasswordInputField()

  const isFetching = useMemo(
    () =>
      fetchingActionsList.includes(RESET_PASSWORD) ||
      fetchingActionsList.includes(LOGIN_WITH_EMAIL_FIREBASE),
    [fetchingActionsList],
  )

  const errorNotificationMessage = useMemo(
    () => errorMessage || firebaseErrorMessage || firebaseInfoMessage,
    [errorMessage, firebaseErrorMessage, firebaseInfoMessage],
  )

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '',
    [email.isValid, email.value, password.isValid, password.value],
  )

  useEffect(() => {
    return () => {
      dispatch(setUserContactEmailAction(email.value))
    }
  }, [currentEmail, dispatch, email.value, setEmail])

  useEffect(() => {
    window.zE && window.zE('messenger', 'hide')

    return () => {
      window.zE && window.zE('messenger', 'show')
    }
  }, [])

  const loginWithEmail = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(loginWithEmailFirebaseAction(email.value, password.value))
    },
    [dispatch, email.value, password.value],
  )

  const showResetPasswordForm = useCallback(() => {
    dispatch(resetFirebaseErrorMessageAction())
    dispatch(resetFirebaseInfoMessageAction())

    eventLogger.logForgotPassword(source)

    goTo('reset-password')
  }, [dispatch, source])

  const resetError = useCallback(() => {
    dispatch(resetFirebaseErrorMessageAction())
    dispatch(resetErrorAction())
  }, [dispatch])

  const signInFirebase = useCallback(async (provider) => {
    await signInWithPopup(getAuth(), provider)
  }, [])

  const handleContinueWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    await signInFirebase(provider)

    setLoginMethodToLocalStorage(LoginMethod.GOOGLE)
  }, [signInFirebase])

  const handleContinueWithFb = useCallback(async () => {
    await signInFirebase(new FacebookAuthProvider())

    setLoginMethodToLocalStorage(LoginMethod.FACEBOOK)
  }, [signInFirebase])

  const handleContinueWithApple = useCallback(async () => {
    await signInFirebase(new OAuthProvider('apple.com'))

    setLoginMethodToLocalStorage(LoginMethod.APPLE)
  }, [signInFirebase])

  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )

  const hasAdditionalLoginMethods = useMemo(() => {
    if (appName === AppName.DANCEBIT) return false

    return isAndroid ? appName !== AppName.FITME : true
  }, [appName, isAndroid])

  return (
    <>
      <S.Container $isStatic={hasAdditionalLoginMethods}>
        <S.Title>{t('login.title')}</S.Title>
        <S.Form backgroundColor={APP_BG_COLORS[appName]}>
          <InputWithFloatPlaceholder
            value={email.value}
            isValid={email.isValid && !errorNotificationMessage}
            validationText={errorNotificationMessage}
            labelName={t('login.email')}
            marginBottom={12}
            onChange={(e) => {
              setEmail((prevState) => ({ ...prevState, value: e.target.value }))
              resetError()
            }}
          />

          <InputWithFloatPlaceholder
            type="password"
            value={password.value}
            isValid={password.isValid && !errorNotificationMessage}
            hasVisibilityControl
            labelName={t('login.password')}
            onChange={(e) => {
              setPassword((prevState) => ({
                ...prevState,
                value: e.target.value,
              }))
              resetError()
            }}
          />

          <S.ForgotPasswordButton
            type="button"
            color={APP_COLORS[appName]}
            onClick={showResetPasswordForm}
          >
            {t('login.forgotPassword')}
          </S.ForgotPasswordButton>
        </S.Form>
      </S.Container>

      <S.FooterContainer $isStatic={hasAdditionalLoginMethods}>
        <Button
          type="button"
          onClick={loginWithEmail}
          disabled={!isComplete || isFetching}
        >
          {t('button.login')}
        </Button>
        {appName !== AppName.DANCEBIT && (
          <>
            {!(isAndroid && appName === AppName.FITME) && (
              <S.Text>{t('login.or')}</S.Text>
            )}
            {appName !== AppName.FITME && (
              <StyledLoginButton.Google
                type="button"
                onClick={handleContinueWithGoogle}
              >
                {t('login.google')}
              </StyledLoginButton.Google>
            )}
            {appName !== AppName.UPLUV && appName !== AppName.FITME && (
              <StyledLoginButton.Facebook
                type="button"
                onClick={handleContinueWithFb}
              >
                {t('login.facebook')}
              </StyledLoginButton.Facebook>
            )}
            {!isAndroid && (
              <StyledLoginButton.Apple
                type="button"
                onClick={handleContinueWithApple}
              >
                {t('login.apple')}
              </StyledLoginButton.Apple>
            )}
          </>
        )}
        <ContactSupport
          source={EVENT_SOURCE.UNSUBSCRIBE_LOGIN}
          showHelpCenterLink={false}
        />
        <Footer source={EVENT_SOURCE.UNSUBSCRIBE_LOGIN} />
      </S.FooterContainer>
    </>
  )
}
